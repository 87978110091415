import React from "react";
import "./style.css"; // Import du CSS spécifique
import GhostCard from "../GhostCard";

const GhostList = ({ 
  ghosts, 
  selectedEvidences,
  toggleGhostDisabled
}) => {

  return (
    <div className="ghost-list">
      <h3>Entités compatibles :</h3>
      <div className="ghost-cards">
        {ghosts.map((ghost, index) => (
          <GhostCard
            key={index}
            ghost={ghost}
            selectedEvidences={selectedEvidences}
            onToggleDisabled={toggleGhostDisabled}
          />
        ))}
      </div>
    </div>
  );
};

export default GhostList;

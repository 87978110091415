const em = {
  EMF:"EMF niveau 5",
  EMPREINTE:"Empreintes digitales",
  ECRITURE:"Écriture fantomatique",
  TEMPERATURE:"Températures glaciales",
  ORBRES: "Orbes fantomatiques",
  DOTS: "Projecteur D.O.T.S.",
  BOX: "Spirit Box",
};

export const ghosts = [
  {
    name: "Esprit",
    evidences: [em.EMF, em.BOX, em.ECRITURE],
    tips: "",
    strengths: "",
    weaknesses: "Repoussé en brûlant de l'encens",
  },
  {
    name: "Spectre",
    evidences: [em.EMF, em.BOX, em.DOTS],
    tips: "à définir",
    strengths: "Touche presque jamais le sol",
    weaknesses: "Réaction toxique au sel",
  },
  {
    name: "Fantôme",
    evidences: [em.BOX, em.EMPREINTE, em.DOTS],
    tips: "à définir",
    strengths: "Les regarder fait perdre beaucoup de santé mentale",
    weaknesses: "Prendre une photo le fait disparaitre",
  },
  {
    name: "Poltergeist",
    evidences: [em.BOX, em.EMPREINTE, em.ECRITURE],
    tips: "à définir",
    strengths: "Peux jeter plusieurs objet en meme temps avec beaucoup de force",
    weaknesses: "Si rien a jeter, ils sont affaibli",
  },
  {
    name: "Banshee",
    evidences: [em.EMPREINTE, em.ORBRES, em.DOTS],
    tips: "Hurlement dans micro parabolique",
    strengths: "Elles affaiblissent leur cible avant d'attaquer",
    weaknesses: "Target une cible",
  },
  {
    name: "Djinn",
    evidences: [em.EMF, em.EMPREINTE, em.TEMPERATURE],
    tips: "Enlever les plombs",
    strengths: "Se déplace rapidement si vous êtes loins de lui",
    weaknesses: "Couper le courant les rend plus faible",
  },
  {
    name: "Cauchemar",
    evidences: [em.BOX, em.ORBRES, em.ECRITURE],
    tips: "",
    strengths: "Chance d'attaque accru dans le noir",
    weaknesses: "Affaibli avec la lumière",
  },
  {
    name: "Revenant",
    evidences: [em.ORBRES, em.ECRITURE, em.TEMPERATURE],
    tips: "",
    strengths: "Fusée",
    weaknesses: "si il voit pas sa cible il se déplace plus lentement",
  },
  {
    name: "Ombre",
    evidences: [em.EMF, em.ECRITURE, em.TEMPERATURE],
    tips: "",
    strengths: "Difficile à trouver",
    weaknesses: "Ne chasse pas si vous êtes à proximité",
  },
  {
    name: "Démon",
    evidences: [em.EMPREINTE, em.ECRITURE, em.TEMPERATURE],
    tips: "à définir",
    strengths: "Chasse très souvent",
    weaknesses: "Ils craignent les crucifix, moins aggressif si il y en a un",
  },
  {
    name: "Yurei",
    evidences: [em.ORBRES, em.TEMPERATURE, em.DOTS],
    tips: "",
    strengths: "Forte influence sur la santé mentale",
    weaknesses: "Brûler de l'encens dans la pièce les pigera et réduira leur capacité à ce déplacer",
  },
  {
    name: "Oni",
    evidences: [em.EMF, em.TEMPERATURE, em.DOTS],
    tips: "",
    strengths: "Plus actif si les gens sont a proximité, draine la santé mentale",
    weaknesses: "Il chasse plus longtemps",
  },
  {
    name: "Yokai",
    evidences: [em.BOX, em.ORBRES, em.DOTS],
    tips: "",
    strengths: "Parler les énèrves, augmente les chances de chasse",
    weaknesses: "Ils entendent que les voix proche quand ils chassent",
  },
  {
    name: "Hantu",
    evidences: [em.EMPREINTE, em.ORBRES, em.TEMPERATURE],
    tips: "",
    strengths: "Les basse températures permettent aux Hantu de ce déplacer plus rapidement",
    weaknesses: "Ils sont lent si il fait pas froid",
  },
  {
    name: "Goryo",
    evidences: [em.EMF, em.EMPREINTE, em.DOTS],
    tips: "",
    strengths: "Ce montre que si ils y a personne a proximité (caméra)",
    weaknesses: "Reste proche de leur lieux de décès",
  },
  {
    name: "Myling",
    evidences: [em.EMF, em.EMPREINTE, em.ECRITURE],
    tips: "",
    strengths: "Ils sont silencieux lors de la chasse",
    weaknesses: "Ils font souvent des bruits paranormaux",
  },
  {
    name: "Onryo",
    evidences: [em.BOX, em.ORBRES, em.TEMPERATURE],
    tips: "à définir",
    strengths: "Éteindre une flamme peut les pousser à attaquer",
    weaknesses: "si menacées elles attaquent moins",
  },
  {
    name: "Jumeaux",
    evidences: [em.EMF, em.BOX, em.TEMPERATURE],
    tips: "",
    strengths: "Chaque jumeau peut s'énerver et lancer une attaque sur sa proie",
    weaknesses: "Ils intéragissent souvent avec l'environnement en même temps",
  },
  {
    name: "Raiju",
    evidences: [em.EMF, em.ORBRES, em.DOTS],
    tips: "",
    strengths: "Siphonnent l'énergie électrique des instruments à proximité, les rendant plus rapide",
    weaknesses: "Pertubent constamment les instruments électronique",
  },
  {
    name: "Obake",
    evidences: [em.EMF, em.EMPREINTE, em.ORBRES],
    tips: "",
    strengths: "Lorsqu'ils interagissent avec l'environnement, ils laissent rarement de trace",
    weaknesses: "Parfois se transforment laisant derrière elles des preuves uniques",
  },
  {
    name: "Mimic",
    evidences: [em.BOX, em.EMPREINTE, em.TEMPERATURE],
    tips: "",
    strengths: "Pas certain de quoi elles sont capable",
    weaknesses: "Parfois des orbes à proximité",
  },
  {
    name: "Moroï",
    evidences: [em.BOX, em.ECRITURE, em.TEMPERATURE],
    tips: "",
    strengths: "Plus vous êtes faible, plus il est fort",
    weaknesses: "Souffrent d'hyperosmie, les affaiblit pendant de longues durées",
  },
  {
    name: "Deogen",
    evidences: [em.BOX, em.ECRITURE, em.DOTS],
    tips: "",
    strengths: "Ils sentent les vivants. Impossible de ce cacher",
    weaknesses: "Lent quand il est proche de vous, et a besoin de beaucoup d'energie",
  },
  {
    name: "Thayé",
    evidences: [em.ORBRES, em.ECRITURE, em.DOTS],
    tips: "",
    strengths: "Quand vous rentrer sur les lieux, ils deviennent actif",
    weaknesses: "S'affaiblissent avec le temps. Moins aggressif et plus lent",
  },
];

export const evidences = [
  "EMF niveau 5",
  "Empreintes digitales",
  "Écriture fantomatique",
  "Températures glaciales",
  "Orbes fantomatiques",
  "Projecteur D.O.T.S.",
  "Spirit Box",
];

import React, { useState, useEffect } from "react";
import "./App.css";
import EvidenceInput from "./components/EvidenceInput";
import GhostList from "./components/GhostList";
import { getCombinations } from "./utils/combinations";
import { evidences, ghosts as initialGhosts } from "./utils/data"; // Import des données

function App() {
  const [selectedEvidences, setSelectedEvidences] = useState([]);
  const [excludedEvidences, setExcludedEvidences] = useState([]);
  const [ghosts, setGhosts] = useState(
    initialGhosts.map((ghost) => ({ ...ghost, isDisabled: false })) // Ajout de isDisabled
  );

  const { compatibleGhosts, possibleEvidences, impossibleEvidences } = getCombinations(
    selectedEvidences,
    excludedEvidences
  );

  const updatedCompatibleGhosts = compatibleGhosts.map((ghost) => {
    const foundGhost = ghosts.find((g) => g.name === ghost.name);
    return foundGhost ? { ...ghost, isDisabled: foundGhost.isDisabled } : ghost;
  });
  
  const toggleSelectEvidence = (evidence) => {
    if (selectedEvidences.includes(evidence)) {
      setSelectedEvidences((prev) => prev.filter((e) => e !== evidence));
    } else {
      setSelectedEvidences((prev) => [...prev, evidence]);
      setExcludedEvidences((prev) => prev.filter((e) => e !== evidence));
    }
  };

  const toggleExcludeEvidence = (evidence) => {
    if (excludedEvidences.includes(evidence)) {
      setExcludedEvidences((prev) => prev.filter((e) => e !== evidence));
    } else {
      setExcludedEvidences((prev) => [...prev, evidence]);
      setSelectedEvidences((prev) => prev.filter((e) => e !== evidence));
    }
  };

  const toggleGhostDisabled = (ghostName) => {
    setGhosts((prevGhosts) => {
      const updatedGhosts = prevGhosts.map((ghost) =>
        ghost.name === ghostName
          ? { ...ghost, isDisabled: !ghost.isDisabled }
          : ghost
      );
      console.log("Updated ghosts:", updatedGhosts); // Vérifie que l'état change correctement
      return updatedGhosts;
    });
  };

  const handleKeyPress = (event) => {
    const key = event.key;
  
    // Vérifie si c'est une touche numérique entre 0 et 6
    if (key >= "0" && key <= "6") {
      const index = parseInt(key, 10);
      const evidence = evidences[index];
  
      if (!evidence) return;
  
      if (!selectedEvidences.includes(evidence) && !excludedEvidences.includes(evidence)) {
        toggleSelectEvidence(evidence);
      } else if (selectedEvidences.includes(evidence)) {
        toggleExcludeEvidence(evidence);
      } else if (excludedEvidences.includes(evidence)) {
        setExcludedEvidences((prev) => prev.filter((e) => e !== evidence));
      }
    }
  
    // Vérifie si la touche "R" est pressée pour le reset
    if (key.toLowerCase() === "r") {
      resetAll();
    }
  };
  
  useEffect(() => {
    // Ajoute un écouteur d'événements pour les touches du clavier
    window.addEventListener("keydown", handleKeyPress);

    // Nettoie l'écouteur lorsque le composant est démonté
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [selectedEvidences, excludedEvidences]); // Dépend des états pour les mettre à jour
  
  const resetAll = () => {
    setSelectedEvidences([]); // Réinitialise les indices sélectionnés
    setExcludedEvidences([]); // Réinitialise les indices exclus
    setGhosts((prevGhosts) =>
      prevGhosts.map((ghost) => ({ ...ghost, isDisabled: false })) // Réinitialise les fantômes
    );
  };
  

  return (
    <div className="app">
      <h1>PHASMOHELPER</h1>
      <EvidenceInput
        selectedEvidences={selectedEvidences}
        excludedEvidences={excludedEvidences}
        allEvidences={evidences}
        possibleEvidences={possibleEvidences}
        impossibleEvidences={impossibleEvidences}
        onToggleSelect={toggleSelectEvidence}
        onToggleExclude={toggleExcludeEvidence}
        onReset={resetAll}
      />
      <GhostList
        ghosts={updatedCompatibleGhosts}
        selectedEvidences={selectedEvidences}
        toggleGhostDisabled={toggleGhostDisabled}
      />
    </div>
  );
}

export default App;

import React from "react";
import "./style.css";

const GhostCard = ({ ghost, selectedEvidences, onToggleDisabled }) => {
  const { name, evidences, tips, strengths, weaknesses, isDisabled } = ghost;

  const isEvidenceHighlighted = (evidence) => !selectedEvidences.includes(evidence);

  return (
    <div
      className={`ghost-card ${isDisabled ? "disabled" : ""}`}
      onContextMenu={(e) => {
        e.preventDefault(); // Empêche le menu contextuel par défaut
        e.stopPropagation(); // Empêche la propagation de l'événement
        onToggleDisabled(name); // Basculer l'état activé/désactivé
      }}
    >
      <h3 className="ghost-name">{name}</h3>
      <div className="ghost-section">
        <h4>Indices nécessaires :</h4>
        <ul className="ghost-evidences">
          {(evidences || []).map((evidence, index) => (
            <li
              key={index}
              className={isEvidenceHighlighted(evidence) ? "highlighted-evidence" : ""}
            >
              {evidence}
            </li>
          ))}
        </ul>
      </div>
      <div className="ghost-section">
        <h4>Astuces pratiques :</h4>
        <p className="ghost-section">{tips}</p>
      </div>
      <div className="ghost-section">
        <h4>Points forts :</h4>
        <p className="ghost-section">{strengths}</p>
        <h4>Points faibles :</h4>
        <p className="ghost-section">{weaknesses}</p>
      </div>
    </div>
  );
};


export default GhostCard;

import { ghosts, evidences } from "./data";

export function getCombinations(selectedEvidences, excludedEvidences) {
  const compatibleGhosts = ghosts.filter((ghost) =>
    selectedEvidences.every((evidence) => ghost.evidences.includes(evidence)) &&
    !excludedEvidences.some((evidence) => ghost.evidences.includes(evidence))
  );

  const possibleEvidences = compatibleGhosts
    .flatMap((ghost) => ghost.evidences)
    .filter(
      (evidence) =>
        !selectedEvidences.includes(evidence) && !excludedEvidences.includes(evidence)
    );

  const impossibleEvidences = evidences.filter(
    (evidence) =>
      !possibleEvidences.includes(evidence) &&
      !selectedEvidences.includes(evidence) &&
      !excludedEvidences.includes(evidence)
  );

  return {
    compatibleGhosts,
    possibleEvidences: [...new Set(possibleEvidences)],
    impossibleEvidences,
  };
}

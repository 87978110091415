import React from "react";
import "./style.css";

const EvidenceInput = ({
  selectedEvidences,
  excludedEvidences,
  allEvidences,
  possibleEvidences,
  impossibleEvidences,
  onToggleSelect,
  onToggleExclude,
  onReset,
}) => {
  const getButtonClass = (evidence) => {
    if (selectedEvidences.includes(evidence)) return "selected"; // Jaune
    if (excludedEvidences.includes(evidence)) return "excluded"; // Gris barré
    if (impossibleEvidences.includes(evidence)) return "impossible"; // Rouge
    if (possibleEvidences.includes(evidence)) return "available"; // Vert
    return ""; // Par défaut
  };

  return (
    <div className="evidence-input">
      <h3>Indices disponibles :</h3>
      <div className="evidence-buttons">
        {/* Première ligne : indices 4-6 + RESET */}
        <div className="evidence-row">
          {allEvidences.slice(4, 7).map((evidence, index) => (
            <button
              key={index + 4}
              className={`evidence-button ${getButtonClass(evidence)}`}
              onClick={() => {
                if (!impossibleEvidences.includes(evidence)) {
                  onToggleSelect(evidence); // Gérer le clic gauche
                }
              }}
              onContextMenu={(e) => {
                e.preventDefault(); // Empêcher le menu contextuel
                onToggleExclude(evidence); // Gérer le clic droit
              }}
            >
              {evidence}
            </button>
          ))}
          {/* Bouton RESET */}
          <button key="reset" className="reset-button" onClick={onReset}>
            RESET
          </button>
        </div>
        {/* Deuxième ligne : indices 0-3 */}
        <div className="evidence-row">
          {allEvidences.slice(0, 4).map((evidence, index) => (
            <button
              key={index}
              className={`evidence-button ${getButtonClass(evidence)}`}
              onClick={() => {
                if (!impossibleEvidences.includes(evidence)) {
                  onToggleSelect(evidence); // Gérer le clic gauche
                }
              }}
              onContextMenu={(e) => {
                e.preventDefault(); // Empêcher le menu contextuel
                onToggleExclude(evidence); // Gérer le clic droit
              }}
            >
              {evidence}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EvidenceInput;
